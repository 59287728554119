.marketing_text {

    h4 {
        font-size: 50px;
    }

    p {
        color: brown
    }
}

.connect-container {
    min-height: 220px;
    background-color: #fff;
    border-radius: 10px;
    padding-top: 45px;
    margin: 0 auto;

    img {
        height: 50px;
        display: flex;
        margin: 0 auto;
    }
}

.demo-text {
    margin-top: 1rem;
    font-weight: 500;
}