@import 'components/global.scss';

.campaign {

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  background-color: $c-purple;

}
