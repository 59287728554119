$mobileS : 320px;
$mobileL : 572px;
$tablet  : 768px;

// .landing-container{
//     width: 50%;
//     margin: 0 auto;
//     @media screen and (min-width: $mobileS) and (max-width: $mobileL) {
//         width: 100%;
//     }
//     @media screen and (min-width: $mobileL) and (max-width: $tablet) {
//         width: 80%;
//     }
//     .video-file{
//         display: flex;
//         justify-content: center;
//     }
//     .video{
//         width: 85%;
//     }
// }

.carousel img {
  width: 62%;
  display: inline-block;
  pointer-events: none;
}

.Collapsible__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .Collapsible__trigger.is-open svg {
    transform: rotate(180deg);
  }

  .Collapsible__trigger.is-close {

  }