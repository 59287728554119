.pagination {
    display: flex;

    justify-content: right;
    margin-top: 40px;

    span {
        font-size: 18px;
    }
   

   
}