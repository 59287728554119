.faq-row-wrapper {
    .faq-title {
        padding-left: 20px;
    }
    
    .faq-body {
        .faq-row {
            .row-title {
                padding-left: 20px;
            }

            .row-content {
                .row-content-text {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }
}