
$smallWidth: 595px;

.img-wrapper{
    width:100%;
    display:flex;
    justify-content:center; 
    align-items:center;
    margin-bottom: 15px;
    
}
.img-logo{
  width: 25%;
  margin-top: 20px;
  @media screen and (max-width: $smallWidth){
    width: 40%;
}
  @media only screen and (min-width: 889px) and (max-width: 1600px){
    width: 20%;
  }
}
